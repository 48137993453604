/**
 * Formats path definitions for use with SVG `<animate>` element
 * @param {[string, string]} definitions - Array of starting and ending SVG path definitions
 * @param {boolean} reverse - Whether start and end values should be reversed
 * @returns Formatted string for `values` attribute of SVG `<animate>` element
 * @example
 * getPathValues(['M2,2 L8,8', 'M8,2 L2,8'], true)
 * returns 'M8,2 L2,8;M2,2 L8,8'
 * @category Utils
 */
export const getSvgAnimateValues = ([start, end]: [string, string], reverse: boolean) =>
  (reverse ? [end, start] : [start, end]).join(';')
